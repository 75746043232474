<template>
  <div class="error404">
    <div class="wrapper">
      <div class="content">
        <span>404</span>
        <el-button @click="$router.go(-1)">Назад</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error404",
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;

  .content {
    margin: auto;
    text-align: center;

    span {
      font-size: 190px;
      display: block;
      font-weight: 600;
    }
  }
}
</style>
